import _ from 'lodash';

import Markdown from '../../../sharedComponents/Markdown';

import './index.scss';

function ChatMessage({
  message,
}) {
  const {
    id,
    properties,
  } = message;

  const propertiesByName = _.keyBy(properties, 'name');

  const {
    replyType,
    messageText,
  } = propertiesByName;

  return (
    <>
      <div
        key={ id }
        className={ `chat-message-container ${ replyType.value === 'user' ? 'user' : 'system' }` }
      >
        <div className="chat-message-inner">
          <div className="chat-message-text">
            { replyType.value === 'user'
              ? messageText.value
              : (<Markdown>{ messageText.value }</Markdown>)
            }
          </div>
        </div>
      </div>
    </>
  );
}

export default ChatMessage;

import React, { createContext, useContext, useState, useEffect } from 'react';

const mockUserData = {
  githubUsername: 'jdebeer',
  name: 'Jacq de Beer',
  username: 'jdebeer',
  location: 'New York, NY',
  website: 'ajdebeer.com',
  twitter: '@jacqdebeer',
  linkedin: 'in/ajdebeer',
  skills: ['JavaScript', 'TypeScript', 'React', 'Node.js']
};

const UserContext = createContext();

const getUserGithubReadme = async (gitUsername) => {
  const repoUrl = `https://raw.githubusercontent.com/${gitUsername}/${gitUsername}/main/README.md`;

  try {
    const response = await fetch(repoUrl);
    if (!response.ok) {
      throw new Error('Network response was not ok ' + response.statusText);
    }
    return response.text();
  }
  catch (err) {
    return 'Could not load github profile';
  }
}

export function UserProvider({ children }) {
  const [userData, setUserData] = useState(mockUserData);

  useEffect(() => {
    getUserGithubReadme(userData.githubUsername)
      .then(content => {
        setUserData(prevData => ({
          ...prevData,
          bio: content,
        }));
      });
  }, [userData.githubUsername]);

  const value = {
    userData,
    setUserData,
  };

  return (
    <UserContext.Provider value={value}>
      {children}
    </UserContext.Provider>
  );
}

export function useUserContext() {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUserContext must be used within a UserProvider');
  }
  return context;
} 
import ReactMarkdown from 'react-markdown';
import { CopyBlock, dracula } from 'react-code-blocks';

export default function Markdown({ children }) {
  return (
    <ReactMarkdown
      children={children}
      components={{
        code: (config) => {
          const { node, inline, className, children, ...props } = config;
          const language = className?.replace('language-', '');
          const isInline = inline !== undefined ? inline : !/\n/.test(children);

          if (isInline) {
            return <code className={ `${className ? className : ''} inline` } {...props}>{children}</code>;
          } else {
            return (
              <CopyBlock
                text={String(children).replace(/\n$/, '')}
                language={language || 'text'}  // Ensure default language is set
                wrapLines={false}
                theme={dracula}
              />
            );
          }
        },
      }}
    />
  );
}